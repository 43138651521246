import React from "react";
import "../Styles/Skills.css";
import ScrollAnimation from '../Services/ScrollAnimation';

const Skills = () => {
    return (
        <div className="wrapper-skills" id="skillsPage">
            <div className="skills-layout">
                {/* Left section (Skill levels) */}
                <ScrollAnimation>
                    <div className="skill-section-card">
                        <h1 className="heading">Experience</h1>
                        <p className="skills-info">
                            Here are some of the skills I have developed over the years.<br />
                            The colors indicate my level of experience.
                        </p>
                        <div className="skill-level-indicators">
                            <div className="skill-level-indicator">
                                <div className="circle beginner"></div>
                                <span>I'm just starting out with this.</span>
                            </div>
                            <div className="skill-level-indicator">
                                <div className="circle advanced"></div>
                                <span>I'm doing well at this.</span>
                            </div>
                            <div className="skill-level-indicator">
                                <div className="circle expert"></div>
                                <span>I feel comfortable doing this.</span>
                            </div>
                            <div className="skill-level-indicator">
                                <div className="circle master"></div>
                                <span>I feel at home doing this.</span>
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>

                {/* Right section (Skill cards) */}
                <div className="skills-container">
                    <ScrollAnimation>
                        <div className="skill-card">
                            <h2>Programming Languages</h2>
                            <ul className="skill-list">
                                <li><span className="circle advanced"></span>C</li>
                                <li><span className="circle expert"></span>C++</li>
                                <li><span className="circle expert"></span>C#</li>
                                <li><span className="circle expert"></span>JavaScript</li>
                                <li><span className="circle expert"></span>TypeScript</li>
                                <li><span className="circle beginner"></span>SQL</li>
                            </ul>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation>
                        <div className="skill-card">
                            <h2>Database & Tools</h2>
                            <ul className="skill-list">
                                <li><span className="circle beginner"></span>MySQL</li>
                                <li><span className="circle master"></span>Firebase</li>
                                <li><span className="circle beginner"></span>MongoDB</li>
                                <li><span className="circle advanced"></span>Git</li>
                                <li><span className="circle beginner"></span>Postman</li>
                                <li><span className="circle expert"></span>Docker</li>
                            </ul>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation>
                        <div className="skill-card">
                            <h2>Frameworks</h2>
                            <ul className="skill-list">
                                <li><span className="circle master"></span>React</li>
                                <li><span className="circle expert"></span>React Native</li>
                                <li><span className="circle advanced"></span>Next.js</li>
                                <li><span className="circle advanced"></span>.NET</li>
                                <li><span className="circle advanced"></span>Entity Framework</li>
                                <li><span className="circle advanced"></span>NSubstitute</li>
                            </ul>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation>
                        <div className="skill-card">
                            <h2>Design Tools</h2>
                            <ul className="skill-list">
                                <li><span className="circle beginner"></span>Adobe Illustrator</li>
                                <li><span className="circle beginner"></span>Figma</li>
                                <li><span className="circle expert"></span>Rotato</li>
                                <li><span className="circle beginner"></span>Rive</li>
                            </ul>
                        </div>
                    </ScrollAnimation>
                </div>
            </div>
        </div>
    );
};

export default Skills;