import React from "react";
import "../Styles/Projects.css";
import ScrollAnimation from '../Services/ScrollAnimation';

const projectData = [
    {
        title: "E-commerce website",
        description: "This is a description of project 1.",
        link: "/project-1"
    },
    {
        title: "TALKiE",
        description: "This is a description of project 2.",
        link: "/project-2"
    },
    {
        title: "Project 3",
        description: "This is a description of project 3.",
        link: "/project-3"
    },
    {
        title: "Project 4",
        description: "This is a description of project 4.",
        link: "/project-4"
    },
    {
        title: "Project 5",
        description: "This is a description of project 5.",
        link: "/project-5"
    },
    {
        title: "Project 6",
        description: "This is a description of project 6.",
        link: "/project-6"
    }
];

const Projects = () => {
    return (
        <div className="wrapper-projects" id="projectsPage">
            <ScrollAnimation>
                <div className="left-side">
                    <h1>My Work</h1><br/>
                    <p>
                        A collection of projects I've worked on.
                    </p>
                </div>
            </ScrollAnimation>
            <ScrollAnimation>
                <div className="right-side">
                    {/* Tekst i venstre hjørne */}
                    <div className="top-left-text">
                        <h4>Get an insight</h4>
                    </div>

                    <div className="cards-container">
                        {projectData.map((project, i) => (
                            <div className="card" key={i}>
                                <div className="card-content">
                                    <h3>{project.title}</h3>
                                    <p>{project.description}</p>
                                    <a href={project.link} className="card-button">
                                        <span>Read More</span>
                                        <span className="thin-arrow">→</span>
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </ScrollAnimation>

            {/* To bokse nedenunder kort-containeren */}
            <ScrollAnimation>
                <div className="bottom-boxes-container">
                    <div className="box">
                        <h3>Box 1</h3>
                        <p>This is some content for box 1.</p>
                    </div>
                    <div className="box">
                        <h3>Box 2</h3>
                        <p>This is some content for box 2.</p>
                    </div>
                </div>
            </ScrollAnimation>
        </div>
    );
}

export default Projects;