import React from "react";
import "../Styles/About.css";
import logo from "../Assets/Profile.jpg";
import ScrollAnimation from '../Services/ScrollAnimation';

const About = () => {
    return (
        <div className="wrapper-about" id="aboutPage">
            <div className="column-left">
                {/* Insert Portrait */}
                <ScrollAnimation>
                <img className="portrait-box" src={logo} alt="Portrait"></img>
                </ScrollAnimation>
            </div>
            <div className="column-right">
                <ScrollAnimation>
                    <div className="about-content">
                        <h1>About me</h1>
                        <p className="about-text">
                            My name is Mathias, I'm 26 years old. I'm currently a software engineering student at Aarhus
                            University, where I'm in
                            my 5th semester. I have been programming for two years and have a particular interest in
                            front-end development.<br></br><br></br>
                            I have always been fascinated by technology and how it can be used to create solutions
                            in people's everyday lives.<br></br><br></br>
                            An example of this is my first project, where I helped develop an app aimed at the China
                            Studies at Aarhus University.
                            The app was designed to help students improve their verbal skills in Chinese through various
                            everyday scenarios.
                            <br></br><br></br>
                        </p><br></br>
                        <p>
                            <strong className="label">From </strong><span className="value">Horsens, Denmark</span><br></br><br></br>
                            <strong className="label">Living in </strong><span className="value">Aarhus, Denmark</span><br></br><br></br>
                            <strong className="label">Development Drive </strong><span className="value">Front-end</span><br></br><br></br>
                            <strong className="label">Occupation </strong><span className="value">Studying Software Engineering in Computer Science</span><br></br><br></br>
                            <strong className="label">Utility </strong><span className="value">MacBook Pro M1 Pro Max, 16-inch, 2021</span>
                        </p>
                    </div>
                </ScrollAnimation>
            </div>
        </div>
    );
}

export default About;
