import React, { useState } from 'react';
import { Link } from 'react-scroll';
import '../Styles/Navbar.css';
import { FaBars, FaTimes } from 'react-icons/fa';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    
    return (
        <div className="wrapper-navbar">
            <div className="logo-container">
                <a href="/" className="poppins-thin">
                    {/* <img src={logo} alt="Logo" className="navbar-logo" /> */}
                    <span className="navbar-logo">
                        <span className="jetbrains-mono-code-header"></span>
                        MATHIAS FOGHT_
                    </span>
                </a>
            </div>
            <div className={`menu-icon ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
                {isOpen ? <FaTimes /> : <FaBars />}
            </div>
            <div className={`buttons-container ${isOpen ? 'open' : ''}`}>
                <Link to="aboutPage" smooth={true} duration={500} offset={-10} onClick={toggleMenu}>
                    <button className="styled-button hover-underline-animation" id="aboutMe">About Me</button>
                </Link>
                <Link to="projectsPage" smooth={true} duration={500} offset={-10} onClick={toggleMenu}>
                    <button className="styled-button hover-underline-animation" id="projects">My Work</button>
                </Link>
                <Link to="skillsPage" smooth={true} duration={500} offset={-10} onClick={toggleMenu}>
                    <button className="styled-button hover-underline-animation" id="skills">Experience</button>
                </Link>
                <Link to="contactPage" smooth={true} duration={500} offset={-10} onClick={toggleMenu}>
                    <button className="styled-button hover-underline-animation" id="contact">Contact</button>
                </Link>
            </div>
        </div>
    );
};

export default Navbar;
